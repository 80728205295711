import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormHelperText, Stack, Tooltip } from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocales } from '../../locales';
import { emptyTotalExtraPrice, getSummary, sendRentableTotalPrice, } from '../../redux/slices/reservation';
import { useDispatch } from '../../redux/store';
import Iconify from '../iconify';
export default function RHFDateTimePicker({ name, helperText, multiline, rows, type, inputRef, id, label, isEditReservation, isDisabled, ...other }) {
    const { translate } = useLocales();
    const { control, setValue } = useFormContext();
    const [isOpened, setIsOpened] = useState(false);
    const dispatch = useDispatch();
    const handlePickerChange = (value) => {
        if (isEditReservation !== undefined) {
            if (['endDate', 'startDate'].includes(name)) {
                dispatch(sendRentableTotalPrice(0));
                dispatch(emptyTotalExtraPrice(0));
                dispatch(getSummary([]));
                setValue('extras', []);
                setValue('discount', '');
                setValue('overload', '');
                setValue(`manualPrice`, '');
                setValue('pricingGroupId', '');
            }
        }
        setValue(name, value, { shouldValidate: true });
    };
    const currentDate = new Date();
    const roundedMinutes = Math.ceil(currentDate.getMinutes() / 15) * 15;
    const getPickerValue = (value) => {
        if (isOpened) {
            if (['endDate', 'startDate'].includes(name)) {
                currentDate.setMinutes(roundedMinutes);
            }
            else {
                currentDate.setMinutes(currentDate.getMinutes());
            }
            if (value !== null && value !== undefined) {
                return new Date(value);
            }
            setValue(name, new Date(currentDate), { shouldValidate: true });
            return new Date(currentDate);
        }
        return value ? new Date(value) : null;
    };
    return (_jsx(Controller, { name: name, defaultValue: null, control: control, render: ({ field: { value }, fieldState: { error } }) => {
            const pickerValue = getPickerValue(value);
            return (_jsxs(Stack, { sx: { display: 'flex', flexDirection: 'column', gap: 0.2 }, children: [_jsxs(Stack, { sx: {
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '10px',
                            width: '100%',
                        }, children: [_jsx(MobileDateTimePicker, { label: `${translate(label)}`, value: pickerValue, onChange: handlePickerChange, minutesStep: ['endDate', 'startDate'].includes(name) ? 15 : 5, onOpen: () => setIsOpened(true), slotProps: {
                                    textField: {
                                        error: false,
                                    },
                                }, sx: {
                                    width: '100%',
                                    '& .MuiInputLabel-root': {
                                        color: error && typeof error?.message === 'string' ? '#FF5630' : undefined,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: error && typeof error?.message === 'string' ? '#FF5630' : undefined,
                                        },
                                        '&:hover fieldset': {
                                            borderColor: error && typeof error?.message === 'string' ? '#FF5630' : undefined,
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'black',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: 'black',
                                        },
                                    },
                                }, format: "dd/MM/yyyy HH:mm" }), _jsx(Tooltip, { title: `${translate(helperText)}` || `${translate(label)}`, placement: "right-start", children: _jsx(Iconify, { icon: "ri:information-line", width: 23, sx: { cursor: 'help', color: '#9AA6B2' } }) })] }), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, children: error ? `${translate(error?.message)}` : `${translate(helperText)}` }))] }));
        } }));
}
